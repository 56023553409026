.term-modal {

  .MuiPaper-root {
    max-width: 850px;
    overflow: auto;
  }

  .text-term {
    font-weight: 500;

    .link {
      color: #f26522;
    }
  }

  .img-term {
    max-width: 220px;

    @media only screen and (max-width: 700px) {
      max-width: 150px;
    }
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: 600px) {
      justify-content: space-around;
    }
  }

  .button-term {
    height: 40px;

    @media (max-width: 600px) {
      min-width: 80px;
      padding: 9px 5px;
    }
  }


}

.eighth__detail-modal {
  .MuiPaper-root {
    max-width: 450px;
    overflow: auto;
  }

  .button-term {
    height: 40px;
  }
}

#contract {
  .help {
    cursor: pointer;
  }

  .steps {

    &__footer {
      position:fixed;
      bottom:0;
      top:auto; 
      max-width:100%;
      width:100%;
      height:250px
    }

    
    &__mobile-footer{
        flex-direction: column;
        flex-wrap: nowrap;

        .step-space{
          padding-bottom: 250px;
        }
    }

    &__values-info {
      margin-top: 56px;
    }

    &__full-height {
      height: 100%;
    }

    &__justify-center {
      display: flex;
      justify-content: center;
    }

    &__flex-align-center {
      display: flex;
      align-items: center;

      @media only screen and (max-width: 960px) {
        justify-content: center;
      }
    }

    &__flex-wrap{
        flex-wrap: nowrap;
    }

    &__side-main {
      background-image: url('../../img/bg-quotation.png');
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__finish-main {
      &__link {
        color: #f26522;
        text-decoration: underline;
        cursor: pointer;
      }

      background-image: url('../../img/contract/bg-finish.png');
      background-repeat: no-repeat;
      background-size: contain;

      @media only screen and (min-width: 1555px) {
        background-size: cover;
      }

      @media only screen and (max-width: 800px) {
        background-image: none;
      }

      @media (max-width: 600px) {
        align-content: start;
        flex-direction: column;
        justify-content: start;
      }

    }

    &__finish-footer {
      background-image: url('../../img/contract/bg-finish-pottencial.png');
      background-repeat: no-repeat;
      background-size: cover;
      width: 340px;
      height: 340px;

      @media only screen and (max-width: 1250px) {
        width: 300px;
        height: 300px;
      }

      @media only screen and (max-width: 960px) {
        width: 250px;
        height: 250px;
      }

      @media only screen and (max-width: 800px) {
        background-image: none;
      }
    }

    &__side-text {
      max-width: 300px;
      margin-right: 80px;
      margin-top: 32px;

      @media only screen and (max-width: 600px) {
        margin-right: 0px;
      }
    }

    &__main-block {
      &__divider-title {
        height: 5px;
        width: 55px;
        background-color: #f26522;
      }

      &__back-button {
        cursor: pointer;
      }
    }

    &__box-main {
      &__title-step {
        font-weight: 500;
      }

      span {
        font-weight: 500;
      }

      @media only screen and (max-width: 600px) {
        padding: 12px;
      }
    }

    &__text-wrap {
      overflow-wrap: break-word;
    }

    &__secundary-color-text {
      color: #555555;
      font-weight: 100;
    }

    &__font-text {
      line-height: 22px;

      @media only screen and (max-width: 960px) {
        font-size: 15px;
      }
    }

    // Finish
    &__finish-text {
      padding: 55px;
      max-width: 590px;

      @media (max-width: 600px) {
        padding: 20px;
      }

      .MuiTypography-body1 {
        font-weight: 800;
      }
    }

    &__img-patti {
      @media only screen and (max-width: 960px) {
        margin-top: 20px;
        width: 100%;
      }
    }

    &__final-button {
      text-align: center;
    }

  }

  .box-imagem-essencial {

    @media (max-width: 600px) {
      min-width: 37px;
      width: 37px;
    }
  }

  .box-text{

    @media (max-width: 600px) {
      width: 70%;
    }
  }

  .space-field-adress {

    @media (max-width: 600px) {
      padding: 4px 16px 4px 16px !important;
    }
  }

  .terms-container {
    @media (max-width: 600px) {
      margin-top: 15px !important;
    }
  }

  .space-field-about-you {

    @media (max-width: 600px) {
      padding: 4px 12px 5px 12px !important;
    }
  }

  .flex-end {
    display: flex;
    align-items: flex-end;
  }

  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .flex-column-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .card-buttons {
    .MuiPaper-root {
      border: 1px solid #ffffff;
    }

    &__active {
      color: #f26522;
      .MuiPaper-root {
        border: 1px solid #f26522;
      }
    }

    &__disabled {
      filter: grayscale(1);

      .MuiPaper-root {
        border: 1px solid #0000002e;
      }

      p {
        color: #808080a1;
      }
    }

    &__paper-card {
      width: 85px;
      height: 85px;
      border: 1px solid transparent;
    }

    &__radio-group {

      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      padding-top: 10px;

      @media (max-width: 600px) {
        flex-direction: row;
        justify-content: space-between;
      }

      .div-form-control-label {

        @media (max-width: 600px) {
          margin-right: 0px !important;
          width: 45% !important;
        }
      }

      .div-property {

        @media (max-width: 600px) {
          display: flex;
          flex-direction: column;
          min-width: 100%;
        }
      }

      .div-typography-box {

        @media (max-width: 600px) {
          margin-left: 0;
          margin-top: 10px;
        }
      }

      .div-typography{
        @media (max-width: 600px) {
           white-space: nowrap;
        }
      }

      .MuiFormControlLabel-root {
        margin-left: 0;
        margin-bottom: 16px;

        display: flex;                                                            
        flex-wrap: wrap;
        flex-direction: row !important;                                                                          
      } 
    }
  }

  // Steps
  .seventh {
    &__coverage-paper {
      min-height: 80px;
      position: relative;

      &__plus-icon {
        position: absolute;
        top: 5px;
        right: 5px;
      }

      &__button {
        border-radius: 14px;
        min-height: 80px;
      }
    }

    &__input-box {
      .MuiFormHelperText-root {
        margin-left: 0;
      }

      .MuiInputBase-root {
        padding-right: 45px;
      }
    }

    &__circular-progress {
      width: 26px !important;
      height: 26px !important;
    }
  }

  .eighth {
    &__slider-box {
      .slick-prev:hover,
      .slick-prev:focus,
      .slick-next:hover,
      .slick-next:focus,
      .slick-disabled {
        opacity: 0.55 !important;
        color: #f26522;
        outline: none !important;
        background: transparent !important;
      }

      .slick-slide {
        display: flex !important;
        justify-content: center !important;
      }
    }

    &__anchor-button {
      position: absolute;
      right: 8px;
      top: 6px;

      .MuiIconButton-root {
        padding: 8px;

        .MuiIconButton-label {
          height: 15px;
        }
      }
    }

    &__remove-button {
      .MuiIconButton-root .MuiIconButton-label {
        font-size: 35px;
      }
    }

    &__add-button {
      .MuiIconButton-root .MuiIconButton-label {
        font-size: 25px;
      }
    }

    &__paper-card {
      max-width: 250px;
      margin-bottom: 22px;
      margin-right: 16px;
      margin-left: 8px;
      position: relative;
      padding-top: 1px;
      margin-top: 5px;

      .MuiAccordion-root {
        border-radius: 15px;

        .MuiAccordionSummary-content {
          margin-bottom: 25px;
          margin-top: 25px;
        }

        .MuiAccordionSummary-expandIcon {
          transform: rotate(0deg) !important;
        }
      }

      transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

      .MuiAccordionSummary-root {
        border-radius: 15px;
      }

      .MuiAccordionSummary-root:hover {
        background-color: #3c3c3c0d;
      }

      .MuiOutlinedInput-input {
        padding: 13.5px 14px;
      }
    }

    &__selected-card {
      border: 1px solid #f26522;
    }

    &__margin-scroll {
      margin-right: 24px;

      @media only screen and (max-width: 600px) {
        margin-right: 0px;
      }
    }

    &__img {
      max-width: 60px;
    }

    &__description {
      line-height: 1.63;
    }

    &__box-coverages {
      direction: rtl;
      padding: 35px;
      margin-right: 24px;

      @media only screen and (max-width: 600px) {
        padding-left: 12px;
        padding-right: 5px;
        margin-right: 0px;
      }
    }
  }

  .tenth {
    &__tab-item {
      .MuiButtonBase-root {
        box-shadow: 3px 3px 10px #00000029;
        border-radius: 14px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
      }

      .MuiTabs-scroller {
        padding-top: 10px;

        .Mui-selected {
          box-shadow: 2px 1px 10px #f2652273;
          color: #f26522;
        }

        .MuiTabs-indicator {
          background-color: transparent;
        }

        button {
          margin-left: 10px;
        }
      }
    }
  }

  .eleventh {
    &__side-form {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__paper-card {
      border-radius: 5px;
    }

    &__active {
      background-color: #f26522;

      svg {
        fill: #fff;
      }

      .MuiTypography-root {
        color: #fff;
      }
    }

    &__card-button {
      height: 100%;
      width: 100%;
      border-radius: 14px;
    }

    &__broker-disclaimer {
      font-weight: bold;
    }

    &__radio-group {
      .MuiFormControlLabel-root {
        margin-left: 0px;
      }
    }


    &__box-options{
      @media (max-width: 1280px) {
        flex-wrap: nowrap !important;
        justify-content: space-evenly !important;
     }
    }

    &__form-payment{
      @media (max-width: 1280px) {
        margin-left: 0px !important;
        margin-right: 0px !important;
     }
    }

    &__cards{

      @media (max-width: 1280px) {
        width: 120px !important;
        height: 71px !important;
     }
     
    }
  }

  .values {
    padding-top: 40px;
    padding-bottom: 24px;
    height: max-content;
    width: 440px;
    margin-right: 32px;

    @media (max-width: 1280px) {
      width: 100%;
      padding-top: 10px; 
      margin-left: 0px !important; 
      margin-right: 0; 
      border-radius: 0;
   }

    @media only screen and (max-width: 700px) {
      margin-left: 16px;
    }

    &__title {

        @media (max-width: 600px) {
          text-align: center;
      }
    }

    &__withFooter{
        @media (max-width: 1280px) {
            display: none;
        }
    }

    &__labelAuto{
      @media (max-width: 600px) {
        margin-top: auto;
      }
    }

    &__value-card {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 24px;
      padding-bottom: 16px;
      border-bottom: 1px solid #8080803d;
    }

    &__value-info {
      background-image: url('../../img/contract/squares-top.svg');
      background-repeat: no-repeat;
      background-position: bottom 30px right -30px;

      @media (max-width: 1280px) {
        display: none !important;
     }
    }

    &__value-total {
      background-image: url('../../img/contract/squares-bottom.svg');
      background-repeat: no-repeat;
      background-position-y: center;
      background-position-x: -30px;
    }

    &__download {
      .MuiButton-outlinedPrimary {
        color: #f26522;
        border: 2px solid #f26522;
      }

      .MuiButton-outlinedPrimary:hover {
        border: 2px solid rgb(242 101 34 / 67%);
        background-color: rgba(242, 101, 34, 0.04);
      }
    }
  }

  .cards {
    &__option-card {
      height: 100%;
      width: 100%;
      border-radius: 14px;
      padding: 16px;

      img {
        width: 100%;
      }
    }
  }
}

.custom-app-bar__logo {
  max-height: 65px;
}