$LaranjaPottencial: #f26522;

@keyframes slidein {
  from {
    top: -184px;
    width: 150%;
  }

  to {
    top: 0;
    width: 100%;
  }
}

@keyframes dropSiledIn {
  from {
    margin-top: 15px;
  }

  to {
    margin-top: 0;
  }
}

@keyframes loginInFade {
  from {
    opacity: 0.5;
  }

  to {
    opacity: 1;
  }
}

#new-main-header {
  position: absolute;
  z-index: 9;
  width: 100%;

  .new-header {
    position: absolute;
    z-index: 9;
    font-family: 'Open Sans', sans-serif;
    width: 100%;
    background-color: #fff;
    -webkit-box-shadow: 1px 10px 14px -1px rgba(0, 0, 0, 0.49);
    box-shadow: 1px 10px 14px -1px rgba(0, 0, 0, 0.49);

    .logo {
      margin-right: 35px;
      top: 28px;
      left: 37px;
      width: 194px;

      a {
        display: inline-block;

        /** Mobile. */
        @media (max-width: 1023px) {
          img {
            max-width: 230px;
            max-height: 49px;
          }
        }
        @media (min-width: 1023px) {
          img {
            max-height: 65px;
            width: auto;
          }
        }
        
      }
    }

    .menu-container {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 2;
      height: 60px;

      .menu-left {
        display: flex;
        align-items: center;
        padding-left: 10px;

        @media (max-width: 414px) {
          padding-left: 10px;
        }

        ul {
          display: flex;
          margin-left: 20px;
          li {
            padding: 20px 20px 20px;
            cursor: pointer;
            color: #fff;
            font-size: 12px;
            font-weight: 600;
            display: flex;
            align-items: center;
            a {
              color: #fff;
            }

            ul {
              display: none;
              position: absolute;
              left: 0;
              top: 70%;
              opacity: 0;
              visibility: hidden;
            }

            &:hover {
              &:after {
                content: '';
                background-color: #fff;
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                height: 6px;
                transform-origin: 50% 50%;
                transform: scaleX(0);
                transition: transform ease-in-out;
              }

              ul {
                display: block;
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                pointer-events: initial;
              }
            }
          }
          @media (max-width: 1024px) {
            display: none;
          }
        }
      }

      .menu-right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        height: 60px;
        padding-right: 10px;

        ul {
          display: flex;
          align-items: center;
          height: 100%;

          li {
            height: 100%;
            display: flex;
            align-items: center;
          }

          a,
          button {
            height: 100%;
            display: flex;
            align-items: center;
            border-bottom: 4px solid #fff;
            padding: 5px 20px 0px 20px;
          }

          .active {
            border-bottom: 4px solid $LaranjaPottencial;
          }
        }

        @media (max-width: 1254px) {
          display: none;
        }

        .button-login {
          font-size: 12px;
          font-weight: 600;
          background: rgba(255, 255, 255, 0.366);
          border-radius: 30px;
          padding: 12px 29px;
          display: block;
          margin-left: 20px;
          text-transform: uppercase;
          transition: all 0.5s ease-in-out;
          cursor: pointer;
          background-color: #fff;
          color: $LaranjaPottencial !important;
          border: 2px solid $LaranjaPottencial;

          span {
            color: $LaranjaPottencial;
          }
        }

        .go-to-broker {
          text-transform: uppercase;
          color: $LaranjaPottencial;
          font-weight: 700;
          font-size: 14px;
        }
      }

      .link-right {
        color: #191919;
        font-family: 'Poppins', sans-serif;
        font-size: 15px;
      }

      .menu-toggler {
        color: #fff;
        display: flex;
        align-items: center;

        span {
          font-family: 'Poppins', sans-serif;
          font-size: 12px;

          @media (max-width: 410px) {
            display: none;
          }

        }

        img {
          height: 13px;
          margin-left: 1rem;
        }
      }
    }

    &.menu-white {
      background-color: #fff;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.29);
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      animation-duration: 1s;
      animation-name: slidein;

      .menu-container {
        padding: 1rem 2rem;
        @media (max-width: 641px) {
          padding: 2rem 2rem;
        }

        .menu-left {
          ul {
            li {
              color: #010101;
              a {
                color: #010101;
              }
              &:hover {
                &:after {
                  background-color: $LaranjaPottencial;
                }
              }
            }
          }
        }

        .menu-toggler {
          span {
            font-family: 'Poppins', sans-serif;
            font-size: 12px;
          }

          @media (max-width: 1254px) {
            display: block;
            span {
              color: #f26522;
            }
          }
        }
      }
    }
  }
}

#pop-up-login {
  .MuiPopover-paper {
    border-radius: 14px;
    border-top: 3px solid #f26522;
  }

  .paper {
    min-width: 300px;
    padding: 25px;
  }

  .title {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
    font-weight: bold;

    .mark {
      color: $LaranjaPottencial;

      a {
        color: $LaranjaPottencial;
      }
    }
  }

  .button {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .body-text {
    font-family: Open Sans, sans-serif;
    font-size: 14px;
  }

  .divider {
    background-color: #f265225e;
    margin-bottom: 30px;
  }

  img {
    width: 23px;
  }
}
