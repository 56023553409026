.modal-info {
  &__core {
    height: calc(100% - 16px);
    margin: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__paper {
    width: 90%;
    padding: 24px;
    position: relative;
    max-height: 100%;

    @media (max-width: 959px) {
      width: 95%;
    }
  }

  &__no-padding {
    padding: 0px !important;
  }

  &__close {
    top: 16px;
    right: 16px;
    cursor: pointer;
    position: absolute;
  }
}
