#main-header {
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  z-index: 50;

  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 40px;
    height: 65px;

    @media (max-width: 1200px) {
      padding: 0 40px;
    }

    .flex-menu-left {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 65px;
      width: 273px;
      left: 3055px;

      @media (max-width: 1200px) {
        padding: 0;
      }
    }

    .logo {
      margin-right: 35px;
      top: 28px;
      left: 37px;
      width: 194px;

      a {
        display: inline-block;

        /** Mobile. */
        @media (max-width: 1023px) {
          img {
            width: 159px;
            height: 49px;
          }
        }
      }
    }

    .menu-toggler {
      display: none;

      /** Mobile. */
      @media (max-width: 1023px) {
        display: block;
      }
    }

    .right {
      display: flex;
      align-items: center;
      width: 100%;

      /** Mobile. */
      @media (max-width: 1023px) {
        display: none;
      }

      .navigation {
        position: relative;
        top: -1px;
        margin-right: 45px;

        > ul {
          display: flex;
          align-items: center;

          > li {
            &:not(:last-child) {
              margin-right: 20px;
            }

            span {
              color: white;
              font-size: 12px;
              font-weight: normal;
              display: inline-block;
            }

            > a {
              color: white;
              font-size: 12px;
              font-weight: normal;
              padding-bottom: 10px;
              position: relative;

              &::after {
                content: '';
                background-color: white;
                position: absolute;
                right: 0;
                bottom: 0;
                left: 0;
                height: 2px;
                transform-origin: 50% 50%;
                transform: scaleX(0);
                transition: transform time(default) ease(inout);
              }

              &:hover {
                &::after {
                  transform: scaleX(1);
                }
              }
            }

            &.has-children {
              position: relative;
              display: flex;
              align-items: center;
              bottom: -2px;

              img {
                display: inline-block;
                margin-left: 10px;
              }

              .submenu {
                position: absolute;
                top: 10px;
                left: 0;
                right: 0;
                opacity: 0;
                height: 800px;
                visibility: hidden;
                transform: translateY(10px);
                transition-property: opacity, visibility, transform;
                transition-duration: time(default);
                transition-timing-function: ease(inout);
                display: flex;
                justify-content: center;
                pointer-events: none;
                padding-top: 30px;

                ul {
                  background-color: color(grey, light);
                  white-space: nowrap;
                  position: relative;
                  border-radius: 5px;

                  &::before {
                    content: '';
                    position: absolute;
                    top: -7px;
                    left: calc(50% - 6px);
                    width: 1px;
                    height: 1px;
                    border-right: 6px solid transparent;
                    border-bottom: 6px solid white;
                    border-left: 6px solid transparent;
                  }

                  li {
                    white-space: nowrap;

                    a {
                      color: color(grey, normal);
                      font-size: 14px;
                      font-weight: normal;
                      display: block;
                      height: 38px;
                      display: flex;
                      align-items: center;
                      padding: 1px 40px 0 15px;
                      transition-property: color, background-color;
                      transition-duration: time(default);
                      transition-timing-function: ease(inout);

                      &:hover {
                        background-color: rgba(242, 101, 34, 0.1);
                        color: color(grey, dark);
                      }
                    }
                  }
                }
              }

              &:hover .submenu {
                opacity: 1;
                visibility: visible;
                transform: translateY(0);
                pointer-events: initial;
              }
            }
          }
        }
      }

      .phones {
        display: flex;
        align-items: center;

        img {
          display: inline-block;
        }

        a {
          color: white;
          font-size: 14px;
          font-weight: normal;
          padding: 0 10px;
          transition: opacity time(default) ease(inout);

          &:nth-child(2) {
            border-right: 1px solid white;
          }

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}
