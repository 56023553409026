* {
  box-sizing: border-box;
}

*:focus,
*.focus {
  outline: 0 transparent !important;
}

/* MAIN STRUCT */
html {
  height: 100%;
  font-size: 16px;
}

body {
  min-height: 100%;
  margin: 0;
  overflow-x: hidden;
  font-family: 'Open Sans', sans-serif;
}

#root {
  display: block;
  @extend body;
}

// BLIP CHAT
#blip-chat-container {
  #blip-chat-open-iframe {
    width: 40px !important;
    height: 40px !important;
    @media (max-width: 1279px) {
      min-width: 40px;
      min-height: 40px;
    }
  }
}

.loader {
  &__root {
    display: flex;
    align-items: center;
    background-color: #00000030;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2000;
  }

  &__svg-container {
    width: 50px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  height: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9e9e9e41;
  border-radius: 20px;
  height: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #73717194;
}
