#page-new-home {
  width: 100%;

  .title-section-global {
    font-size: 32px !important;

    hr {
      width: 60px;
      height: 6px;
      padding: 0.5px;
      border: none;
      margin-top: 14px;
      background: #f26522;
    }
  }

  .section-banner {
    position: relative;
    margin-top: 60px;

    .background {
      background-size: cover;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      video {
        width: 100%;
        filter: brightness(0.7);
      }
    }

    .content-banner {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      font-family: "Open Sans", sans-serif;
      z-index: 2;
    }

    .banner-container {

      width: 650px;
      margin-left: 60px;

      @media (max-width: 410px) {
        margin-left: 30px;
        width: 100%;
      }
    }

    .title-banner-container {

      margin-bottom: 17px;
      line-height: 80px;
      text-align: left;
      font: normal normal bold 55px/60px Poppins;
      letter-spacing: -1.1px;
      color: #ffffff;
      text-shadow: 0px 3px 6px #00000029;
      opacity: 1;

      @media (max-width: 770px) {
        font-size: 30px !important;
        line-height: 32px;
        margin-bottom: 4px;
        width: 312px;
      }

      @media (max-width: 410px) {
        font-size: 20px !important;
        line-height: 25px;
        width: 288px;
      }

    }

    .sub-title-banner-container {
      margin-bottom: 17px;
      line-height: 80px;
      text-align: left;
      font: normal normal 100 20px/27px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      min-width: 470px;

      @media (max-width: 770px) {
        width: 312px;
        font-size: 15px !important;
        min-width: 336px !important;
        margin-bottom: 8px;
        line-height: 16px;
      }

      @media (max-width: 410px) {
        width: 288px;
      }

      
    }

    .wrapper-cta {
      display: flex;
    }

    .wrapper-column {
      padding-left: 10px;
    }

    .wrapper-text {
      line-height: 80px;
      text-align: left;
      font: normal normal normal 17px/23px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
      @media (max-width: 770px) {
        font-size: 15px !important;
      }
    }

    .wrapper-valor {
      text-align: left;
      font: normal normal 600 20px/28px Open Sans;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;

      @media (max-width: 375px) {
        font: normal normal 600 15px/28px Open Sans;
      }
    }
  }

  .section-assistance {
    justify-content: space-around;
    background: #fbfbfb 0% 0% no-repeat padding-box;
    opacity: 1;
    position: relative;
    padding: 90px 46px;

    @media (max-width: 770px) {
      padding: 90px 8px;
    }

    .container-assistance {
      position: relative;
      max-width: 100%;
      display: flex;
      flex-direction: column-reverse;
      flex-basis: max-content;

      @media (max-width: 1266px) {
        align-items: center;
      }

      @media (max-width: 1289px) {
        align-items: center;
      }
      @media (max-width: 770px) {
        align-items: center;
        justify-content: center;
        padding: 20px 24px;
      }

      .wrapper-cta {
        @media (max-width: 770px) {
          display: flex;
        }
      }

      .title-div-icon {

        .box-assistance {

          @media (max-width: 770px) {
            margin-bottom: 10px;
          }

          font: normal normal bold 21px/22px Poppins;
          letter-spacing: 0px;
          color: #f26522;
          opacity: 1;
          text-align: center;
          padding-bottom: 5px;
          padding-right: 5px;
        }
  
        .box-button {
          text-align: center;
          margin-left: 40px;
  
          margin-top: 10px !important;

          @media (max-width: 770px) {
            margin-left: 0px;
          }
        }

      }

    }

    .container-icon {
      max-width: 100%;
      padding-top: 20px;
      display: flex;
      flex-direction: column;

      .tabs {
        .title {
          font-family: Poppins, sans-serif;
          font-weight: 900;
          text-decoration: underline;
        }

        .subtitle {
          font-size: 9px;
          text-align: left;
        }

        .selected {
          .title {
            color: #f26522;
          }

          .subtitle {
            margin-bottom: 7px;
          }
        }
      }

      .MuiBox-root-29{
        
        @media (max-width: 410px) {
          padding: 16px !important;
        }
      }

      .tab-item {
        .MuiTabs-indicator {
          width: 0 !important;
          background-color: none !important;
        }

        .MuiTab-root {
          border-radius: 30px;
          min-width: 120px;
        }
      }
    }

    .title-div {
      flex-direction: column;
      align-items: center;
      font: normal normal bold 35px/40px Open Sans;
      letter-spacing: 0px;
      opacity: 1;
      line-height: 148%;
    }

    .title-div-button {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    
      
    }

    .title-div-icon {
      flex-direction: column;
      align-items: center;
      margin-top: 30px;

      .wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 60px;

        @media (max-width: 1400px) {
          column-gap: 5px;
        }

        @media (max-width: 440px) {
          grid-template-columns: auto;
        }
      }

      .box {
        padding-bottom: 30px;
        display: flex;
        flex-direction: inherit;
        align-items: center;
      }

      .box-imagem {
        padding: 0px 20px 0px 0px;

        @media (max-width: 700px) {
          padding: 0px;
        }

        img {
          min-width: 48px;

          @media (max-width: 700px) {
            min-width: 37px;
            width: 37px;
            padding: 0px 10px 0px 0px;
          }
        }
      }
   

      .box-text {
        max-width: 220px;
        line-height: 17px;

        @media (max-width: 700px) {
          font-size: 12px;
          max-width: 123px!important;
        }

        @media (max-width: 1200px) {
          max-width: 131px;
        }
      }
    }

    .title-assistance {
      text-align: left;
      font-family: Poppins;
      font-size: 32px;
      font-weight: bold;

      @media (max-width: 770px) {
        font: normal normal bold 32px/38px Poppins;
        margin-bottom: 16px;
      }
      span {
        color: #f26522;
        .TextColor {
          color: #f26522;
        }
      }
    }

    .subtitle-assistance {
      color: #070606ad;
      display: flex;
      font-size: 17px;
      font-weight: 500;
      font-family: Open Sans, sans-serif;
      margin-top: 30px;
      margin-bottom: 30px;

      @media (max-width: 770px) {
        padding: 0 0rem 0rem;
        line-height: 2rem;
        margin: 0px 30px 30px 0px;
      }
    }

    .wrapper-cta {
      .link {
        display: block;
        margin-top: 80px;
        position: relative;
        color: white;
      }
    }
  }

  .section-coverages {
    color: #2e2c2c;
    background-color: #fbfbfb;

    @media (max-width: 1200px) {
      color: #070606ad;
    }

    .background {
      padding: 90px 46px;
      background-size: 1600px;
      background-position: center top;
      z-index: 1;
      background-repeat: space;
      background-image: url("../img/home/SectionWhatYouNeed/bg-coverages.png");

      @media (min-width: 1560px) {
        background-size: cover;
      }

      @media (max-width: 1200px) {
        background: none;
      }
    }

    .container-full {
      max-width: 800px;
      margin-bottom: 40px;
    }

    .container-coverages {
      max-width: 100%;
      flex-direction: column;
      margin-top: 50px;
    }

    .button-roof {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .title {
        font-family: Poppins, sans-serif;
        text-decoration: underline;
        margin-top: 14px;
        font-size: 22px;
        cursor: pointer;
      }

      .subtitle {
        font-size: 12px;
        text-align: left;
      }

      .selected {
        .title {
          color: #f26522;
          margin-top: 12px;
          font-weight: bold;
        }

        .subtitle {
          margin-left: 26px;
        }
      }

      .notSelected {
        margin-left: 24px;
        margin-right: 65px;
      }
    }

    .title-coverages {
      font: normal normal bold 20px/52px Poppins;
      margin-bottom: 75px;

      .title-section-global {
        @media (max-width: 1200px) {
          color: #070606;
        }
      }

      @media (max-width: 770px) {
        padding: 2rem 0 0rem;
      }
    }

    .subtitle-coverages {
      display: flex;
      font-size: 19px;
      font-weight: 100;
      font-family: Open Sans, sans-serif;
      line-height: 25px;
      margin-top: 30px;
      margin-bottom: 30px;
      max-width: 800px;
    }

    .Line {
      display: block;
      height: 0px;
      border-bottom: 3px solid #e26d38;
      margin: inherit;
      width: 244px;

      @media (max-width: 770px) {
        width: 120px;
      }
    }

    .boxes-div {
      display: grid;
      grid-gap: 39px;
      grid-template-columns: repeat(3, 240px);

      @media (max-width: 1124px) {
        grid-template-columns: repeat(auto-fill, minmax(182px, 1fr));
        justify-items: center;
        justify-content: center;
      }
    }

    .box-item {
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      background-color: #ffffff;
      border-radius: 15px;
      padding: 24px;
      padding-bottom: 15px;
      position: relative;

      button {
        width: 100%;
        height: 100%;
      }

      .learn-more {
        color: #f26522;
        font-style: italic;
        font-size: 14px;
      }

      .icon-more {
        position: absolute;
        right: 20px;
        top: 15px;

        span {
          font-size: 22px;
          color: #f26522;
        }
      }
    }

    .box-thumb {
      height: 70px;

      img {
        width: 50px;
        height: 50px;
      }
    }

    .box-name {
      font-family: Poppins;
      font-size: 12px;
      font-weight: 600;
      color: #e26d38;
      text-align: left;
      line-height: 1.5;
      padding-top: 10px;
    }

    .box-name-sub {
      line-height: 18px;
      font-family: Poppins;
      font-size: 15px;
      font-weight: 600;
      padding: 0 0 0.8rem;
      color: #555555;
      line-height: 1.5;
      text-align: left;
    }

    .box-list {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      line-height: 1.8rem;
    }

    .box-list-item {
      line-height: 21px;
      color: #555555;
      font-family: Open Sans, sans-serif;
      height: 90px;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: initial;
      text-align: left;
      font-weight: 500;
    }

    .container-mobile {
      display: flex;
      flex-direction: column;
      gap: 16px;
      min-width: 549px;
      @media (max-width: 600px) {
        min-width: 100%;
        max-width: 500px;
      }

      .buttons {
        display: flex;
        gap: 16px;
        flex-wrap: wrap;
        justify-content: space-between;

        button {
          font: 600 12px Poppins;
          background: transparent;
          border: 2px white solid;
          box-shadow: 0px 6px 12px #00000029;
          align-self: center;
          white-space: pre;
          &.Ativo {
            background-color: white;
            color: #f26522;
          }

          @media (max-width: 1200px) {
            color: #070606ad;
          }
        }

        @media (max-width: 528px) {
          justify-content: center;
        }
      }
    }
  }

  .SectionPersonAndPerson {
    display: flex;
    justify-content: center;
    background-color: #c6c6c645;
    padding: 90px 46px;
    position: relative;
    h2 {
      top: 3622px;
      left: 789px;
      height: 73px;
      text-align: left;
      font: normal normal bold 32px/48px Poppins;
      letter-spacing: -0.64px;
      color: #000000;
      opacity: 1;
    }

    .SubtitlelPersonAndPerson {
      text-align: left;
      font: normal normal 600 24px/35px Poppins;
      letter-spacing: 0px;
      color: #555555;
      opacity: 1;
      top: 3769px;
      left: 75px;
      width: 540px;
      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .DescriptionPersonAndPerson {
      margin-top: 20px;
      @media (max-width: 414px) {
        margin-top: 1.5rem;
      }

      p {
        left: 75px;
        width: 520px;
        margin-bottom: 18px;
        text-align: left;
        font: normal normal normal 16px/19px Open Sans;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        @media (max-width: 414px) {
          padding-bottom: 20px;
          width: 100%;
        }
      }

      .OurValuesPersonAndPerson {
        font-weight: 700;
      }
    }

    .Numbers {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 0.7rem;

      .ValuesColumum1 {
        list-style-type: disc !important;
        @media (max-width: 414px) {
          margin-bottom: 10px;
        }
      }

      .ItemNumbers {
        padding: 0px 70px 0px 0px;
        font: normal normal normal 16px/30px Open Sans;
        color: #000000;
        opacity: 1;
      }
    }

    .ImagesDiv {
      .banner {
        opacity: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        @media (max-width: 414px) {
          padding: 0px;
        }

        img {
          width: 100%;
          object-fit: contain;

          @media (max-width: 375px) {
            width: 126%;
          }
        }
      }
    }
  }

  .section-customer {
    display: flex;
    justify-content: center;
    color: #fff;
    font-family: "Open Sans", sans-serif;

    .container-customer {
      width: 100%;
      max-width: 100%;
      padding: 90px 46px;
      display: flex;
      background-size: cover;
      background-repeat: no-repeat;
      flex-direction: column;
      background-image: url("../img/home/SectionWhatYouNeed/bg-customer.png");

      @media (max-width: 1050px) {
        background-image: none;
      }

      @media (max-width: 710px) {
        padding: 90px 20px;
      }
    }

    .container-customer:after {
      content: "";
      background: hsla(180, 0%, 50%, 0.25);
      pointer-events: none;
    }

    .title-div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-bottom: 48px;
    }

    .title-customer {
      text-align: left;
      font: normal normal bold 39px/45px Poppins;
      letter-spacing: -0.96px;
      opacity: 1;
      padding: 0 0 1rem;

      hr {
        background-color: #fff;

        @media (max-width: 1050px) {
          background: #f26522;
        }
      }

      @media (max-width: 1050px) {
        color: #070606;
      }

      @media (max-width: 770px) {
        padding: 0 0 0rem;
      }

      @media (max-width: 320px) {
        font: normal normal bold 29px/52px Poppins;
      }
    }

    .description-customer {
      text-align: left;
      font: normal normal 100 19px/22px Open Sans;
      letter-spacing: 0px;

      @media (max-width: 1050px) {
        color: #070606ad;
      }

      @media (max-width: 770px) {
        font: normal normal bold 14px/52px Open Sans;
        padding: 0 0 0rem;
      }

      @media (max-width: 320px) {
        font: normal normal bold 11px/52px Open Sans;
      }
    }

    .line {
      display: block;
      height: 0px;
      border-bottom: 1px solid #00000029;
      margin: inherit;
    }

    .box-item {
      box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
      background-color: #ffffff;
      border-radius: 14px;
      padding: 30px;
      max-width: 630px;
      margin-bottom: 300px;

      @media (max-width: 1520px) {
        margin-bottom: 200px;
      }

      @media (max-width: 1366px) {
        margin-bottom: 100px;
      }

      @media (max-width: 1050px) {
        margin-right: 45px;
        max-width: unset;
      }
      @media (max-width: 770px) {
        margin-right: 0px;
        max-width: unset;
      }
    }

    .box-name {
      justify-content: center;
      display: flex;
      padding: 45px 0px 0px 0px;
    }

    .wrapper {
      display: grid;
      grid-template-columns: 280px 255px;
      padding: 0px;
      justify-content: center;

      @media (max-width: 710px) {
        display: block;
      }
    }

    .box {
      padding-bottom: 50px;
      display: flex;
      flex-direction: inherit;
      align-items: center;

      .arrow .MuiSvgIcon-root {
        font-size: 18px;
        color: #0000008f;
      }

      a,
      button {
        color: #070606ad;
        font-size: 15px;
        font-weight: 500;
        font-family: Open Sans, sans-serif;
        display: flex;
        align-items: center;
      }

      @media (max-width: 710px) {
        justify-content: center;
      }
    }

    .box-line {
      @media (max-width: 1299px) {
        text-align: -webkit-center;
      }
    }

    .box-image {
      padding: 0px 10px 0px 0px;

      img {
        width: 50px;
        height: 45px;
      }
    }

    .box-text {
      line-height: 17px;
      padding: 0px 5px 0px 0px;
    }
  }

  .SectionWND {

    display: flex;
    justify-content: center;
    background-color: #f8f8f8;
    padding: 90px 46px;
    position: relative;

    @media (max-width: 770px) {
      padding: 90px 20px;
    }

    .Container {
      max-width: 100%;
      flex-direction: column;
      .SliderWND {
        margin-left: 16px;
        .BoxImg {
          display: flex !important;
        }
        img {
          max-width: 435px;

        
          @media (max-width: 600px) {
            max-width: 95%;
          }
        }
      }
      .DescriptionsWND {

        @media (max-width: 952px) {
          margin-top: 75px;
        }

        .wrapper-cta {
          display: flex;
    
          @media (max-width: 940px) {
            justify-content: center !important;
            align-items: center !important;
          }
        }

        .title {
          font-size: 18px;
          font-family: Poppins, sans-serif;
          font-weight: 600;
          color: #f26522;
          padding-bottom: 13px;
          display: flex;
        }
        .text {
          text-align: left;
          color: #070606ad;
          display: flex;
          font-size: 17px;
          font-weight: 500;
          font-family: Open Sans, sans-serif;
          line-height: 22px;
          height: 4rem;

          @media (max-width: 651px) {
            height: 6rem;
          }

        }
      }
    }
    .TitleDiv {
      display: flex;
      flex-direction: column;
      padding: 0;
      p {
        font: normal normal normal 20px/30px Open Sans;
        padding: 0px 0px 20px;
      }

      @media (max-width: 651px) {
        margin: 0px 20px 30px 20px;
        text-align: center;
      }
    }
    .TitleWND {
      font: normal normal bold 20px/52px Poppins;
      padding: 12px 15px;
      text-align: left;
      font-family: Poppins;
      font-size: 32px;
      font-weight: bold;
      @media (max-width: 770px) {
        padding: 2rem 0 0rem;
      }
    }
    .SubtitleWND {
      padding: 12px 15px;
      text-align: left;
      color: #070606ad;
      display: flex;
      font-size: 17px;
      font-weight: 500;
      font-family: Open Sans, sans-serif;
      line-height: 22px;
    }
  }
}

a#residencial-site-personalize-seguro-botao:hover {
  color: #fff;
}

a#residencial-site-pottencial-para-voce-botao:hover {
  color: #fff;
}

.slick-dots li {
  margin: 0px;
}

.slick-dots li button:before {
  font-size: 12px !important;
}

.slick-dots li.slick-active button:before {
  color: #f26522;
}

.slick-dots {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.arrow {
  font-family: monospace;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: #f26522;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  cursor: pointer;
}

.arrowPrev {
  margin-right: 10px;
}

.arrowNext {
  margin-left: 10px;
}

// ------------------------

.TitleRewards {
  font-size: 49px;
  font-weight: 600;
  font-family: "Open Sans bold", sans-serif bold;
  @media (max-width: 770px) {
    font: normal normal bold 22px/52px Open Sans;
  }
}

.Listing {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 4rem 0;
  @media (max-width: 770px) {
    justify-content: space-around;
    padding: 2rem 0;
  }
}

.Item {
  padding: 2rem;
  @media (max-width: 770px) {
    padding: 0.5rem;
  }
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.65;
  color: #2d2f38;
}

a {
  color: #4b74fe;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #3463ff;
  text-decoration: underline;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  line-height: 1.3;
}

.text-xs {
  font-size: 12px !important;
}

.text-sm {
  font-size: 14px !important;
}

.text-md {
  font-size: 16px !important;
}

.text-lg {
  font-size: 18px !important;
}

.text-xl {
  font-size: 21px !important;
}

/* Color Systems */
.bg-black {
  background-color: #000 !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-primary {
  background-color: #2f89fc !important;
}

a.bg-primary:hover,
a.bg-primary:focus {
  background-color: #167bfc !important;
}

.bg-accent {
  background-color: #f52e91 !important;
}

a.bg-accent:hover,
a.bg-accent:focus {
  background-color: #f41684 !important;
}

.bg-secondary {
  background-color: #6d728b !important;
}

a.bg-secondary:hover,
a.bg-secondary:focus {
  background-color: #62667d !important;
}

.bg-success {
  background-color: #06cc89 !important;
}

a.bg-success:hover,
a.bg-success:focus {
  background-color: #05b378 !important;
}

.bg-info {
  background-color: #0ea1e6 !important;
}

a.bg-info:hover,
a.bg-info:focus {
  background-color: #0d90ce !important;
}

.bg-warning {
  background-color: #f68718 !important;
}

a.bg-warning:hover,
a.bg-warning:focus {
  background-color: #eb7a09 !important;
}

.bg-danger {
  background-color: #ff4457 !important;
}

a.bg-danger:hover,
a.bg-danger:focus {
  background-color: #ff2b40 !important;
}

.bg-grey {
  background-color: #afb7c1 !important;
}

a.bg-grey:hover,
a.bg-grey:focus {
  background-color: #a1aab6 !important;
}

.bg-dark {
  background-color: #33374b !important;
}

a.bg-dark:hover,
a.bg-dark:focus {
  background-color: #292c3c !important;
}

.bg-light {
  background-color: #f2f4fe !important;
}

a.bg-light:hover,
a.bg-light:focus {
  background-color: #dae0fc !important;
}

.fg-black {
  color: #000 !important;
}

.fg-white {
  color: #fff !important;
}

.fg-primary {
  color: #2f89fc !important;
}

a.fg-primary:hover,
a.fg-primary:focus {
  color: #167bfc !important;
}

.fg-accent {
  color: #f52e91 !important;
}

a.fg-accent:hover,
a.fg-accent:focus {
  color: #f41684 !important;
}

.fg-secondary {
  color: #6d728b !important;
}

a.fg-secondary:hover,
a.fg-secondary:focus {
  color: #62667d !important;
}

.fg-success {
  color: #06cc89 !important;
}

a.fg-success:hover,
a.fg-success:focus {
  color: #05b378 !important;
}

.fg-info {
  color: #0ea1e6 !important;
}

a.fg-info:hover,
a.fg-info:focus {
  color: #0d90ce !important;
}

.fg-warning {
  color: #f68718 !important;
}

a.fg-warning:hover,
a.fg-warning:focus {
  color: #eb7a09 !important;
}

.fg-danger {
  color: #ff4457 !important;
}

a.fg-danger:hover,
a.fg-danger:focus {
  color: #ff2b40 !important;
}

.fg-grey {
  color: #afb7c1 !important;
}

a.fg-grey:hover,
a.fg-grey:focus {
  color: #a1aab6 !important;
}

.fg-dark {
  color: #33374b !important;
}

a.fg-dark:hover,
a.fg-dark:focus {
  color: #292c3c !important;
}

.fg-light {
  color: #f2f4fe !important;
}

a.fg-light:hover,
a.fg-light:focus {
  color: #dae0fc !important;
}

.border-primary {
  border-color: #2f89fc !important;
}

.border-accent {
  border-color: #f52e91 !important;
}

.border-secondary {
  border-color: #6d728b !important;
}

.border-success {
  border-color: #06cc89 !important;
}

.border-info {
  border-color: #0ea1e6 !important;
}

.border-warning {
  border-color: #f68718 !important;
}

.border-danger {
  border-color: #ff4457 !important;
}

.border-grey {
  border-color: #afb7c1 !important;
}

.border-dark {
  border-color: #33374b !important;
}

.border-light {
  border-color: #f2f4fe !important;
}

/* Social Media Colors */
.bg-facebook {
  background-color: #3b5999 !important;
}

.btn.bg-facebook:hover,
.btn.bg-facebook:focus {
  background-color: #344e87 !important;
}

.bg-twitter {
  background-color: #1da1f2 !important;
}

.btn.bg-twitter:hover,
.btn.bg-twitter:focus {
  background-color: #0d95e8 !important;
}

.bg-google-plus {
  background-color: #db4437 !important;
}

.btn.bg-google-plus:hover,
.btn.bg-google-plus:focus {
  background-color: #d33426 !important;
}

.bg-youtube {
  background-color: #cd201f !important;
}

.btn.bg-youtube:hover,
.btn.bg-youtube:focus {
  background-color: #b71d1c !important;
}

.bg-dribbble {
  background-color: #ea4c89 !important;
}

.btn.bg-dribbble:hover,
.btn.bg-dribbble:focus {
  background-color: #e7357a !important;
}

.bg-pinterest {
  background-color: #bd081c !important;
}

.btn.bg-pinterest:hover,
.btn.bg-pinterest:focus {
  background-color: #a50718 !important;
}

.bg-slack {
  background-color: #3aaf85 !important;
}

.btn.bg-slack:hover,
.btn.bg-slack:focus {
  background-color: #349c76 !important;
}

.bg-linkedin {
  background-color: #0077b5 !important;
}

.btn.bg-linkedin:hover,
.btn.bg-linkedin:focus {
  background-color: #00669c !important;
}

.btn {
  padding: 10px 28px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.btn:focus,
.btn.focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-primary {
  color: #fff;
  background-color: #2f89fc;
  border-color: #2f89fc;
}

.btn-primary:hover {
  color: #fff;
  background-color: #167bfc;
  border-color: #0974fb;
  -webkit-box-shadow: 0 4px 12px rgba(47, 137, 252, 0.3);
  box-shadow: 0 4px 12px rgba(47, 137, 252, 0.3);
}

.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #4897fc;
  border-color: #3c90fc;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-primary:disabled,
.btn-primary.disabled {
  color: #fff;
  background-color: #2f89fc;
  border-color: #2f89fc;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0974fb;
  border-color: #046df4;
}

.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-accent {
  color: #fff;
  background-color: #f52e91;
  border-color: #f52e91;
}

.btn-accent:hover {
  color: #fff;
  background-color: #f41684;
  border-color: #f10c7e;
  -webkit-box-shadow: 0 4px 12px rgba(245, 46, 145, 0.3);
  box-shadow: 0 4px 12px rgba(245, 46, 145, 0.3);
}

.btn-accent:focus,
.btn-accent.focus {
  color: #fff;
  background-color: #f6469e;
  border-color: #f63a97;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-accent:disabled,
.btn-accent.disabled {
  color: #fff;
  background-color: #f52e91;
  border-color: #f52e91;
}

.btn-accent:not(:disabled):not(.disabled):active,
.btn-accent:not(:disabled):not(.disabled).active,
.show > .btn-accent.dropdown-toggle {
  color: #fff;
  background-color: #f10c7e;
  border-color: #e50b77;
}

.btn-accent:not(:disabled):not(.disabled):active:focus,
.btn-accent:not(:disabled):not(.disabled).active:focus,
.show > .btn-accent.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-secondary {
  color: #fff;
  background-color: #6d728b;
  border-color: #6d728b;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #62667d;
  border-color: #5c6076;
  -webkit-box-shadow: 0 4px 12px rgba(109, 114, 139, 0.3);
  box-shadow: 0 4px 12px rgba(109, 114, 139, 0.3);
}

.btn-secondary:focus,
.btn-secondary.focus {
  color: #fff;
  background-color: #7a7f97;
  border-color: #737891;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-secondary:disabled,
.btn-secondary.disabled {
  color: #fff;
  background-color: #6d728b;
  border-color: #6d728b;
}

.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #5c6076;
  border-color: #575b6e;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-success {
  color: #fff;
  background-color: #06cc89;
  border-color: #06cc89;
}

.btn-success:hover {
  color: #fff;
  background-color: #05b378;
  border-color: #05a770;
  -webkit-box-shadow: 0 4px 12px rgba(6, 204, 137, 0.3);
  box-shadow: 0 4px 12px rgba(6, 204, 137, 0.3);
}

.btn-success:focus,
.btn-success.focus {
  color: #33374b;
  background-color: #07e59a;
  border-color: #06d891;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-success:disabled,
.btn-success.disabled {
  color: #fff;
  background-color: #06cc89;
  border-color: #06cc89;
}

.btn-success:not(:disabled):not(.disabled):active,
.btn-success:not(:disabled):not(.disabled).active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #05a770;
  border-color: #059a68;
}

.btn-success:not(:disabled):not(.disabled):active:focus,
.btn-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-info {
  color: #fff;
  background-color: #0ea1e6;
  border-color: #0ea1e6;
}

.btn-info:hover {
  color: #fff;
  background-color: #0d90ce;
  border-color: #0c88c2;
  -webkit-box-shadow: 0 4px 12px rgba(14, 161, 230, 0.3);
  box-shadow: 0 4px 12px rgba(14, 161, 230, 0.3);
}

.btn-info:focus,
.btn-info.focus {
  color: #fff;
  background-color: #1cadf1;
  border-color: #10a9f0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-info:disabled,
.btn-info.disabled {
  color: #fff;
  background-color: #0ea1e6;
  border-color: #0ea1e6;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0c88c2;
  border-color: #0b7fb6;
}

.btn-info:not(:disabled):not(.disabled):active:focus,
.btn-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-warning {
  color: #33374b;
  background-color: #f68718;
  border-color: #f68718;
}

.btn-warning:hover {
  color: #fff;
  background-color: #eb7a09;
  border-color: #df7409;
  -webkit-box-shadow: 0 4px 12px rgba(246, 135, 24, 0.3);
  box-shadow: 0 4px 12px rgba(246, 135, 24, 0.3);
}

.btn-warning:focus,
.btn-warning.focus {
  color: #33374b;
  background-color: #f79431;
  border-color: #f68d24;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-warning:disabled,
.btn-warning.disabled {
  color: #33374b;
  background-color: #f68718;
  border-color: #f68718;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
  color: #fff;
  background-color: #df7409;
  border-color: #d36e08;
}

.btn-warning:not(:disabled):not(.disabled):active:focus,
.btn-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-danger {
  color: #fff;
  background-color: #ff4457;
  border-color: #ff4457;
}

.btn-danger:hover {
  color: #fff;
  background-color: #ff2b40;
  border-color: #ff1e35;
  -webkit-box-shadow: 0 4px 12px rgba(255, 68, 87, 0.3);
  box-shadow: 0 4px 12px rgba(255, 68, 87, 0.3);
}

.btn-danger:focus,
.btn-danger.focus {
  color: #fff;
  background-color: #ff5e6e;
  border-color: #ff5162;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-danger:disabled,
.btn-danger.disabled {
  color: #fff;
  background-color: #ff4457;
  border-color: #ff4457;
}

.btn-danger:not(:disabled):not(.disabled):active,
.btn-danger:not(:disabled):not(.disabled).active,
.show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff1e35;
  border-color: #ff1129;
}

.btn-danger:not(:disabled):not(.disabled):active:focus,
.btn-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-grey {
  color: #33374b;
  background-color: #afb7c1;
  border-color: #afb7c1;
}

.btn-grey:hover {
  color: #33374b;
  background-color: #a1aab6;
  border-color: #99a4b0;
  -webkit-box-shadow: 0 4px 12px rgba(175, 183, 193, 0.3);
  box-shadow: 0 4px 12px rgba(175, 183, 193, 0.3);
}

.btn-grey:focus,
.btn-grey.focus {
  color: #33374b;
  background-color: #bdc4cc;
  border-color: #b6bdc7;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-grey:disabled,
.btn-grey.disabled {
  color: #33374b;
  background-color: #afb7c1;
  border-color: #afb7c1;
}

.btn-grey:not(:disabled):not(.disabled):active,
.btn-grey:not(:disabled):not(.disabled).active,
.show > .btn-grey.dropdown-toggle {
  color: #33374b;
  background-color: #99a4b0;
  border-color: #929dab;
}

.btn-grey:not(:disabled):not(.disabled):active:focus,
.btn-grey:not(:disabled):not(.disabled).active:focus,
.show > .btn-grey.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-dark {
  color: #fff;
  background-color: #33374b;
  border-color: #33374b;
}

.btn-dark:hover {
  color: #fff;
  background-color: #292c3c;
  border-color: #242634;
  -webkit-box-shadow: 0 4px 12px rgba(51, 55, 75, 0.3);
  box-shadow: 0 4px 12px rgba(51, 55, 75, 0.3);
}

.btn-dark:focus,
.btn-dark.focus {
  color: #fff;
  background-color: #3d425a;
  border-color: #383d53;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-dark:disabled,
.btn-dark.disabled {
  color: #fff;
  background-color: #33374b;
  border-color: #33374b;
}

.btn-dark:not(:disabled):not(.disabled):active,
.btn-dark:not(:disabled):not(.disabled).active,
.show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #242634;
  border-color: #1e212d;
}

.btn-dark:not(:disabled):not(.disabled):active:focus,
.btn-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-light {
  color: #33374b;
  background-color: #f2f4fe;
  border-color: #f2f4fe;
}

.btn-light:hover {
  color: #33374b;
  background-color: #dae0fc;
  border-color: #ced6fb;
  -webkit-box-shadow: 0 4px 12px rgba(242, 244, 254, 0.3);
  box-shadow: 0 4px 12px rgba(242, 244, 254, 0.3);
}

.btn-light:focus,
.btn-light.focus {
  color: #33374b;
  background-color: white;
  border-color: #fefeff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-light:disabled,
.btn-light.disabled {
  color: #33374b;
  background-color: #f2f4fe;
  border-color: #f2f4fe;
}

.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #33374b;
  background-color: #ced6fb;
  border-color: #c3ccfa;
}

.btn-light:not(:disabled):not(.disabled):active:focus,
.btn-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-primary {
  color: #2f89fc;
  background-color: transparent;
  border-color: #2f89fc;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: #2f89fc;
  border-color: #2f89fc;
  -webkit-box-shadow: 0 4px 12px rgba(47, 137, 252, 0.3);
  box-shadow: 0 4px 12px rgba(47, 137, 252, 0.3);
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  color: #2f89fc;
  background-color: transparent;
  border-color: #2f89fc;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-primary:disabled,
.btn-outline-primary.disabled {
  color: #fff;
  background-color: #2f89fc;
  border-color: #2f89fc;
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: #167bfc;
  border-color: #0974fb;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-accent {
  color: #f52e91;
  background-color: transparent;
  border-color: #f52e91;
}

.btn-outline-accent:hover {
  color: #fff;
  background-color: #f52e91;
  border-color: #f52e91;
  -webkit-box-shadow: 0 4px 12px rgba(245, 46, 145, 0.3);
  box-shadow: 0 4px 12px rgba(245, 46, 145, 0.3);
}

.btn-outline-accent:focus,
.btn-outline-accent.focus {
  color: #f52e91;
  background-color: transparent;
  border-color: #f52e91;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-accent:disabled,
.btn-outline-accent.disabled {
  color: #fff;
  background-color: #f52e91;
  border-color: #f52e91;
}

.btn-outline-accent:not(:disabled):not(.disabled):active,
.btn-outline-accent:not(:disabled):not(.disabled).active,
.show > .btn-outline-accent.dropdown-toggle {
  color: #fff;
  background-color: #f41684;
  border-color: #f10c7e;
}

.btn-outline-accent:not(:disabled):not(.disabled):active:focus,
.btn-outline-accent:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-accent.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-secondary {
  color: #6d728b;
  background-color: transparent;
  border-color: #6d728b;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6d728b;
  border-color: #6d728b;
  -webkit-box-shadow: 0 4px 12px rgba(109, 114, 139, 0.3);
  box-shadow: 0 4px 12px rgba(109, 114, 139, 0.3);
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  color: #6d728b;
  background-color: transparent;
  border-color: #6d728b;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-secondary:disabled,
.btn-outline-secondary.disabled {
  color: #fff;
  background-color: #6d728b;
  border-color: #6d728b;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #62667d;
  border-color: #5c6076;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus,
.btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-success {
  color: #06cc89;
  background-color: transparent;
  border-color: #06cc89;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #06cc89;
  border-color: #06cc89;
  -webkit-box-shadow: 0 4px 12px rgba(6, 204, 137, 0.3);
  box-shadow: 0 4px 12px rgba(6, 204, 137, 0.3);
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  color: #06cc89;
  background-color: transparent;
  border-color: #06cc89;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-success:disabled,
.btn-outline-success.disabled {
  color: #fff;
  background-color: #06cc89;
  border-color: #06cc89;
}

.btn-outline-success:not(:disabled):not(.disabled):active,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #05b378;
  border-color: #05a770;
}

.btn-outline-success:not(:disabled):not(.disabled):active:focus,
.btn-outline-success:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-success.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-info {
  color: #0ea1e6;
  background-color: transparent;
  border-color: #0ea1e6;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #0ea1e6;
  border-color: #0ea1e6;
  -webkit-box-shadow: 0 4px 12px rgba(14, 161, 230, 0.3);
  box-shadow: 0 4px 12px rgba(14, 161, 230, 0.3);
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  color: #0ea1e6;
  background-color: transparent;
  border-color: #0ea1e6;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-info:disabled,
.btn-outline-info.disabled {
  color: #fff;
  background-color: #0ea1e6;
  border-color: #0ea1e6;
}

.btn-outline-info:not(:disabled):not(.disabled):active,
.btn-outline-info:not(:disabled):not(.disabled).active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #0d90ce;
  border-color: #0c88c2;
}

.btn-outline-info:not(:disabled):not(.disabled):active:focus,
.btn-outline-info:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-info.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-warning {
  color: #f68718;
  background-color: transparent;
  border-color: #f68718;
}

.btn-outline-warning:hover {
  color: #33374b;
  background-color: #f68718;
  border-color: #f68718;
  -webkit-box-shadow: 0 4px 12px rgba(246, 135, 24, 0.3);
  box-shadow: 0 4px 12px rgba(246, 135, 24, 0.3);
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  color: #f68718;
  background-color: transparent;
  border-color: #f68718;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-warning:disabled,
.btn-outline-warning.disabled {
  color: #33374b;
  background-color: #f68718;
  border-color: #f68718;
}

.btn-outline-warning:not(:disabled):not(.disabled):active,
.btn-outline-warning:not(:disabled):not(.disabled).active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #eb7a09;
  border-color: #df7409;
}

.btn-outline-warning:not(:disabled):not(.disabled):active:focus,
.btn-outline-warning:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-warning.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-danger {
  color: #ff4457;
  background-color: transparent;
  border-color: #ff4457;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: #ff4457;
  border-color: #ff4457;
  -webkit-box-shadow: 0 4px 12px rgba(255, 68, 87, 0.3);
  box-shadow: 0 4px 12px rgba(255, 68, 87, 0.3);
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  color: #ff4457;
  background-color: transparent;
  border-color: #ff4457;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-danger:disabled,
.btn-outline-danger.disabled {
  color: #fff;
  background-color: #ff4457;
  border-color: #ff4457;
}

.btn-outline-danger:not(:disabled):not(.disabled):active,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: #ff2b40;
  border-color: #ff1e35;
}

.btn-outline-danger:not(:disabled):not(.disabled):active:focus,
.btn-outline-danger:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-danger.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-grey {
  color: #afb7c1;
  background-color: transparent;
  border-color: #afb7c1;
}

.btn-outline-grey:hover {
  color: #33374b;
  background-color: #afb7c1;
  border-color: #afb7c1;
  -webkit-box-shadow: 0 4px 12px rgba(175, 183, 193, 0.3);
  box-shadow: 0 4px 12px rgba(175, 183, 193, 0.3);
}

.btn-outline-grey:focus,
.btn-outline-grey.focus {
  color: #afb7c1;
  background-color: transparent;
  border-color: #afb7c1;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-grey:disabled,
.btn-outline-grey.disabled {
  color: #33374b;
  background-color: #afb7c1;
  border-color: #afb7c1;
}

.btn-outline-grey:not(:disabled):not(.disabled):active,
.btn-outline-grey:not(:disabled):not(.disabled).active,
.show > .btn-outline-grey.dropdown-toggle {
  color: #33374b;
  background-color: #a1aab6;
  border-color: #99a4b0;
}

.btn-outline-grey:not(:disabled):not(.disabled):active:focus,
.btn-outline-grey:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-grey.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-dark {
  color: #33374b;
  background-color: transparent;
  border-color: #33374b;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #33374b;
  border-color: #33374b;
  -webkit-box-shadow: 0 4px 12px rgba(51, 55, 75, 0.3);
  box-shadow: 0 4px 12px rgba(51, 55, 75, 0.3);
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  color: #33374b;
  background-color: transparent;
  border-color: #33374b;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-dark:disabled,
.btn-outline-dark.disabled {
  color: #fff;
  background-color: #33374b;
  border-color: #33374b;
}

.btn-outline-dark:not(:disabled):not(.disabled):active,
.btn-outline-dark:not(:disabled):not(.disabled).active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #292c3c;
  border-color: #242634;
}

.btn-outline-dark:not(:disabled):not(.disabled):active:focus,
.btn-outline-dark:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-dark.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-light {
  color: #f2f4fe;
  background-color: transparent;
  border-color: #f2f4fe;
}

.btn-outline-light:hover {
  color: #33374b;
  background-color: #f2f4fe;
  border-color: #f2f4fe;
  -webkit-box-shadow: 0 4px 12px rgba(242, 244, 254, 0.3);
  box-shadow: 0 4px 12px rgba(242, 244, 254, 0.3);
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  color: #f2f4fe;
  background-color: transparent;
  border-color: #f2f4fe;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-outline-light:disabled,
.btn-outline-light.disabled {
  color: #33374b;
  background-color: #f2f4fe;
  border-color: #f2f4fe;
}

.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active,
.show > .btn-outline-light.dropdown-toggle {
  color: #33374b;
  background-color: #dae0fc;
  border-color: #ced6fb;
}

.btn-outline-light:not(:disabled):not(.disabled):active:focus,
.btn-outline-light:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-light.dropdown-toggle:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.breadcrumb {
  background-color: #f2f4fe;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 1rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: "\2022";
  padding-right: 1rem;
}

.breadcrumb .breadcrumb-item.active {
  color: #a1aab6;
}

.breadcrumb .breadcrumb-item + .breadcrumb-item::before {
  color: #a1aab6;
}

.breadcrumb-dark .breadcrumb-item a {
  color: rgba(255, 255, 255, 0.4);
}

.breadcrumb-dark .breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  color: rgba(255, 255, 255, 0.8);
}

.breadcrumb-dark .breadcrumb-item.active {
  color: rgba(255, 255, 255, 0.8);
}

.navbar {
  min-height: 70px;
}

.navbar-float {
  position: absolute;
  top: 20px;
  left: 0;
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 6px rgba(51, 55, 75, 0.15);
  box-shadow: 0 2px 6px rgba(51, 55, 75, 0.15);
  z-index: 1070;
}

.navbar.sticky {
  z-index: 1080;
}

.navbar.sticky.fixed ~ * {
  margin-top: 70px;
}

.navbar.sticky.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-animation: navTransition 0.5s ease;
  animation: navTransition 0.5s ease;
  -webkit-box-shadow: 0 2px 6px rgba(51, 55, 75, 0.15);
  box-shadow: 0 2px 6px rgba(51, 55, 75, 0.15);
  z-index: 1080;
}

@-webkit-keyframes navTransition {
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
}

@keyframes navTransition {
  from {
    top: -100%;
  }
  to {
    top: 0;
  }
}

.navbar-brand {
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
}

.navbar-nav {
  margin-top: 10px;
  border-top: 1px solid #e4e7ee;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

.navbar-nav .nav-link {
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.navbar-nav .btn {
  font-size: 14px;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(109, 114, 139, 0.75);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: #6d728b;
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #2f89fc;
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav {
    margin-top: 0;
    border-top: none;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav {
    margin-top: 0;
    border-top: none;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-md .navbar-nav {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav {
    margin-top: 0;
    border-top: none;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-lg .navbar-nav {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav {
    margin-top: 0;
    border-top: none;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.form-control {
  padding: 8px 15px;
  height: calc(1.5em + 1.375rem + 2px);
  border-color: #dee1e6;
}

.custom-select {
  height: calc(1.5em + 1.375rem + 2px);
}

.page-link {
  margin-left: 5px;
  min-width: 40px;
  color: #a1aab6;
  border: 1px solid #dee1e6;
  text-align: center;
  border-radius: 4px;
}

.page-link:hover {
  color: #6d728b;
  background-color: #f2f4fe;
  border-color: #dee1e6;
}

.page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #2f89fc;
  border-color: #0974fb;
}

.page-item.disabled .page-link {
  color: #6d728b;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
  line-height: 1.5;
}

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
}

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.img-place {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
}

.img-place img {
  width: auto;
  max-width: 100%;
  height: 100%;
}

.bg-img {
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-img > * {
  position: relative;
  z-index: 10;
}

.bg-img-parallax {
  background-attachment: fixed;
}

.bg-img-overlay-dark {
  position: relative;
}

.bg-img-overlay-dark::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.6;
  background-color: #33374b;
}

.bg-img-size-contain {
  background-size: contain;
}

.bg-img-size-100 {
  background-size: 100% 100%;
}

.avatar {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.avatar-img {
  margin-right: 8px;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar-img img {
  width: 100%;
  height: 100%;
}

.divider {
  position: relative;
  display: block;
  margin: 12px 0 24px 0;
  max-width: 250px;
  height: 2px;
  background-color: #cbd9ff;
  border-radius: 20px;
  border: none;
}

.divider::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 20px;
  width: 40px;
  height: 2px;
  background-color: #2f89fc;
}

.back-to-top {
  position: fixed;
  bottom: 20px;
  right: 25px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: rgba(175, 183, 193, 0.7);
  visibility: hidden;
  cursor: pointer;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  z-index: 1100;
}

.back-to-top::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  margin: -4px auto;
  width: 12px;
  height: 12px;
  border-top: 2px solid #555;
  border-right: 2px solid #555;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.back-to-top:hover {
  background: #2f89fc;
}

.back-to-top:hover::after {
  border-color: #fff;
}

.top-bar {
  display: block;
  height: 35px;
  border-bottom: 1px solid #f0f1f6;
  font-size: 14px;
}

.top-bar .container,
.top-bar .row {
  height: 100%;
}

.top-bar a {
  color: #6d728b;
}

.top-bar a:hover {
  color: #2f89fc;
  text-decoration: none;
}

.social-mini-button a {
  padding-left: 12px;
  padding-right: 12px;
}

.social-mini-button a:not(:last-child) {
  border-right: 1px solid #dfe1eb;
}

.page-banner {
  position: relative;
  height: 340px;
  background-color: #6d728b;
}

.page-banner .container {
  position: relative;
  z-index: 10;
}

.page-banner.home-banner {
  height: auto;
  max-height: 500px;
}

.page-banner.overlay-dark::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(109, 114, 139, 0.75);
  z-index: 1;
}

@media (min-width: 768px) {
  .page-banner.home-banner {
    height: 540px;
    max-height: unset;
  }
}

.slider-wrapper {
  position: relative;
  height: 100%;
}

.hero-carousel {
  height: 100%;
}

.owl-carousel .owl-stage-outer {
  height: 100%;
}

.hero-carousel-item {
  position: relative;
  width: 100%;
  height: 100%;
}

.hero-carousel-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(51, 55, 75, 0.5);
}

.hero-carousel-item img {
  width: 100%;
  height: auto;
}

.hero-carousel .owl-nav {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.hero-carousel:hover .owl-nav {
  opacity: 1;
  visibility: visible;
}

.hero-carousel .owl-nav .owl-prev,
.hero-carousel .owl-nav .owl-next {
  -webkit-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.hero-carousel:hover .owl-nav .owl-prev,
.hero-carousel:hover .owl-nav .owl-next {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.hero-carousel .owl-dots {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
}

.hero-carousel .img-caption {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  padding: 0 16px;
  width: auto;
  max-width: 900px;
  height: 100%;
  text-align: center;
  color: #fff;
  margin-top: 10%;
}

.hero-carousel h1 {
  font-weight: 300;
}

.hero-carousel .subhead {
  color: #fff;
  font-weight: 400;
}

@media (min-width: 768px) {
  .hero-carousel h1 {
    font-size: 56px;
  }
  .hero-carousel .subhead {
    font-size: 20px;
  }
}

.page-section {
  position: relative;
  display: block;
  padding: 80px 0;
}

.page-section p {
  color: #6d728b;
}

.subhead {
  display: block;
  text-transform: capitalize;
  color: #afb7c1;
  font-weight: 500;
  margin-bottom: 8px;
}

.title-section {
  display: block;
  max-width: 450px;
  color: #2d2f38;
  font-weight: 600;
  margin-bottom: 32px;
}

.text-center .title-section {
  margin-left: auto;
  margin-right: auto;
}

.about-img {
  position: relative;
  margin: 15px auto;
  width: auto;
  max-width: 500px;
  border-radius: 12px;
  overflow: hidden;
  -webkit-box-shadow: 0 8px 16px rgba(109, 114, 139, 0.3);
  box-shadow: 0 8px 16px rgba(109, 114, 139, 0.3);
  -webkit-transform: skewX(-3deg) rotateX(20deg);
  transform: skewX(-3deg) rotateX(20deg);
}

.about-img img {
  width: 100%;
}

.team-carousel {
  display: block;
  width: 100%;
}

.team-wrap {
  position: relative;
  margin: 0 auto;
  width: auto;
  max-width: 280px;
}

.team-profile {
  position: relative;
  display: block;
  width: 100%;
  border-radius: 6px;
  background-color: #afb7c1;
  overflow: hidden;
}

.team-content {
  display: block;
  padding: 20px;
  background-color: #fff;
  color: #33374b;
}

.team-content .social-button {
  margin-top: 24px;
}

.team-content .social-button a {
  display: inline-block;
  margin-right: 4px;
  margin-bottom: 4px;
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  font-size: 14px;
  background-color: #f2f4fe;
  color: #6d728b;
  border-radius: 4px;
  -webkit-transition: background 0.2s ease, color 0.3s ease;
  transition: background 0.2s ease, color 0.3s ease;
}

.team-content .social-button a:hover {
  background-color: #2f89fc;
  color: #fff;
  text-decoration: none;
}

.portfolio {
  display: block;
  background-color: #f2f4fe;
  border-radius: 8px;
  overflow: hidden;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.portfolio:hover {
  -webkit-box-shadow: 0 3px 12px rgba(109, 114, 139, 0.2);
  box-shadow: 0 3px 12px rgba(109, 114, 139, 0.2);
}

.portfolio img {
  width: auto;
  max-width: 100%;
}

.card-testimonial {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 30px 20px;
  background-color: #f2f4fe;
  color: #6d728b;
  border-radius: 8px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.card-testimonial:hover {
  background-color: #fff;
}

.card-testimonial .content {
  margin-bottom: 24px;
}

.card-testimonial .author {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.card-testimonial .avatar {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
}

.card-testimonial .avatar img {
  width: 100%;
  height: 100%;
}

.card-testimonial .author-name {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0;
  color: #33374b;
}

.card-testimonial .author-info {
  font-size: 11px;
  color: #a1aab6;
}

.card-blog {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 32px 20px 70px 20px;
  max-width: 260px;
  min-height: 290px;
  border-radius: 6px;
  -webkit-box-shadow: 0 2px 6px rgba(51, 55, 75, 0.16);
  box-shadow: 0 2px 6px rgba(51, 55, 75, 0.16);
}

.card-blog .header {
  margin-bottom: 16px;
}

.card-blog .avatar {
  position: relative;
  display: inline-block;
  margin-right: 6px;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: #d0d4e4;
  overflow: hidden;
}

.card-blog .avatar img {
  width: 100%;
  height: 100%;
}

.card-blog .entry-footer {
  display: inline-block;
  vertical-align: top;
}

.card-blog .post-author {
  font-weight: 500;
  color: #6d728b;
}

.card-blog .post-date {
  font-size: 13px;
  color: #b2b6c5;
}

.card-blog .post-title {
  margin-bottom: 8px;
  font-size: 18px;
}

.card-blog .post-title a {
  font-weight: 500;
  color: #2d2f38;
}

.card-blog .post-excerpt {
  font-size: 14px;
  color: #afb7c1;
}

.card-blog .footer {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.card-blog-row *:first-child .card-blog {
  background-color: #2f89fc;
}

.card-blog-row *:first-child .card-blog .post-author,
.card-blog-row *:first-child .card-blog .post-title a,
.card-blog-row *:first-child .card-blog .footer a {
  color: #fff;
}

.card-blog-row *:first-child .card-blog .post-date {
  color: rgba(255, 255, 255, 0.6);
}

.blog-single-wrap {
  position: relative;
  display: block;
  padding: 16px 0;
}

.blog-single-wrap .post-thumbnail {
  position: relative;
  display: block;
  width: 100%;
  margin-bottom: 32px;
  border-radius: 8px;
  -webkit-box-shadow: 0 4px 24px rgba(109, 114, 139, 0.3);
  box-shadow: 0 4px 24px rgba(109, 114, 139, 0.3);
  overflow: hidden;
}

.blog-single-wrap .post-thumbnail img {
  width: auto;
  max-width: 100%;
}

.blog-single-wrap .post-meta {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  margin-top: 12px;
  margin-bottom: 24px;
}

.blog-single-wrap .post-meta a {
  color: #6d728b;
}

.blog-single-wrap .post-meta a:hover {
  color: #167bfc;
  text-decoration: none;
}

.blog-single-wrap .post-meta .gap {
  padding-left: 8px;
  padding-right: 8px;
}

.blog-single-wrap .post-tags {
  display: block;
  padding: 16px 0;
}

.blog-single-wrap .post-tags a {
  display: inline-block;
  padding: 4px 12px;
  background-color: #f2f4fe;
  color: #99a4b0;
  font-size: 14px;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.blog-single-wrap .post-tags a:hover {
  text-decoration: none;
  background-color: #2f89fc;
  color: #fff;
}

.widget {
  padding: 16px 0;
}

.widget-box {
  position: relative;
  display: block;
  padding: 24px;
  margin-bottom: 32px;
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ebeefd;
  -webkit-box-shadow: 0 4px 12px rgba(109, 114, 139, 0.1);
  box-shadow: 0 4px 12px rgba(109, 114, 139, 0.1);
}

.widget-box::after {
  display: block;
  clear: both;
  content: "";
}

.widget-title {
  font-weight: 600;
  color: #6d728b;
}

.search-form .form-group {
  position: relative;
}

.search-form .btn {
  position: absolute;
  top: 5px;
  right: 6px;
  padding: 6px 12px;
  background-color: #2f89fc;
  color: #fff;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.search-form .btn:hover {
  background-color: #167bfc;
}

.categories {
  position: relative;
  list-style: none;
  padding-left: 0;
}

.categories li {
  display: block;
  float: left;
  margin-bottom: 12px;
  width: 50%;
}

.categories li a {
  display: inline-block;
  width: 90%;
  padding: 6px 12px;
  background-color: #eef5ff;
  color: #2f89fc;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.categories li a:hover {
  text-decoration: none;
  background-color: #2f89fc;
  color: #fff;
}

.categories li a span {
  float: right;
  margin-left: 8px;
  padding: 3px 6px;
  background-color: #2f89fc;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 40px;
}

.categories li a:hover span {
  -webkit-box-shadow: 0 0 0 1px #fff;
  box-shadow: 0 0 0 1px #fff;
}

.blog-item {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding-bottom: 12px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dae0fc;
}

.blog-item .post-thumb {
  -ms-flex-negative: 0;
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  margin-right: 15px;
  width: 100px;
  height: 80px;
  background-color: #afb7c1;
  overflow: hidden;
}

.blog-item .post-thumb img {
  width: auto;
  height: 100%;
}

.blog-item .post-title a {
  color: #6d728b;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.blog-item .post-title a:hover {
  color: #2f89fc;
  text-decoration: none;
}

.blog-item .meta a {
  margin-right: 6px;
  font-size: 12px;
  color: #62667d;
}

.blog-item .meta a:hover {
  text-decoration: none;
}

.tagcloud {
  position: relative;
  display: block;
}

.tag-cloud-link {
  display: inline-block;
  padding: 4px 10px;
  margin-top: 4px;
  margin-bottom: 4px;
  border: 1px solid #dae0fc;
  border-radius: 40px;
  font-size: 14px;
  color: #6d728b;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.tag-cloud-link:hover,
.tag-cloud-link:focus {
  text-decoration: none;
  background-color: #f2f4fe;
  color: #2f89fc;
  border-color: #2f89fc;
}

.filterable-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  margin-bottom: 24px;
}

.filterable-btn .btn {
  margin: 4px;
  padding: 8px 16px;
  font-size: 14px;
  background-color: #f2f4fe;
  color: #898da2;
}

.filterable-btn .btn.active {
  background-color: #2f89fc;
  color: #fff;
}

.grid-item {
  padding: 16px;
  width: 33.33333%;
}

.form-contact .form-control {
  background-color: #f2f4fe;
  border-color: transparent;
}

.form-contact .form-control:focus {
  background-color: transparent;
  border-color: #94c2fd;
  -webkit-box-shadow: 0 0 0 1px #94c2fd;
  box-shadow: 0 0 0 1px #94c2fd;
}

.maps-container {
  position: relative;
  display: block;
  width: 100%;
  height: 400px;
  background-color: #f2f4fe;
  overflow: hidden;
}

#google-maps {
  width: 100%;
  height: 100%;
}

.page-footer {
  position: relative;
  display: block;
  padding-top: 80px;
  padding-bottom: 20px;
  background-color: #33374b;
  color: #fff;
}

.page-footer hr {
  border-color: #464b61;
}

.page-footer h5 {
  margin-bottom: 24px;
}

.page-footer p {
  color: rgba(255, 255, 255, 0.7);
}

.footer-menu {
  position: relative;
  padding-left: 0;
  list-style: none;
}

.footer-menu li {
  margin-bottom: 8px;
}

.footer-menu a {
  color: #afb7c1;
}

.footer-menu a:hover {
  color: #fff;
}

.sosmed-button a {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
  color: rgba(255, 255, 255, 0.6);
  outline: none !important;
}

/* Custom Plugin */
.owl-nav {
  display: block;
  margin: auto;
  text-align: center;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
  position: absolute;
  top: 0;
  bottom: 0;
  display: inline-block;
  margin: auto;
  width: 50px;
  height: 50px;
  line-height: 50px;
  border-radius: 50%;
  text-align: center;
  background-color: #2f89fc;
  color: #fff;
}

.owl-carousel .owl-nav button.owl-next:focus,
.owl-carousel .owl-nav button.owl-prev:focus {
  outline: none;
}

.owl-carousel .owl-nav button.owl-next {
  right: 10px;
}

.owl-carousel .owl-nav button.owl-prev {
  left: 10px;
}

.owl-carousel .owl-dots {
  display: block;
  margin: 16px auto;
  text-align: center;
}

.owl-carousel button.owl-dot {
  display: inline-block;
  margin: 0 4px;
  width: 36px;
  height: 4px;
  background-color: #c6ccd3;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.owl-carousel button.owl-dot:focus {
  outline: none;
}

.owl-carousel button.owl-dot.active {
  background-color: #2f89fc;
}
/*# sourceMappingURL=theme.css.map */
