#mobile-menu {
  background-color: fade-out(black, 0.4);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  pointer-events: none;
  opacity: 0;
  font-family: 'Open Sans', sans-serif;
  visibility: hidden;
  transition-property: opacity, visibility;
  transition-duration: time(default);
  transition-timing-function: ease(inout);

  .content-menu {
    background-color: #fff;
    width: 335px;
    max-width: 100%;
    margin-left: auto;
    height: 100%;

    .logo {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 15px;

      a {
        display: inline-block;

        img {
          max-width: 230px;
          max-height: 49px;
        }
        
      }
    }

    .navigation {
      display: block;
      padding: 10px 0 10px 30px;
      ul {
        li {
          display: block;
          button {
            font-weight: bold;
            text-transform: uppercase;
            color: #101010;
            padding: 15px 0;
            display: block;
            font-size: 12px;
            &:after {
              content: '❯';
              width: 7px;
              height: 7px;
              text-align: center;
              margin-left: 12px;
              margin-top: -5px;
            }
          }
          a {
            display: flex;
            align-items: center;
            color: #000000;
            padding: 12px 0 12px 0;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 12px;
          }
          ul {
            display: none;
            &.dropActiveOne,
            &.dropActiveTwo {
              display: block !important;
            }
            li {
              a {
                text-transform: unset;
                font-weight: normal;
                padding: 12px 0 12px 21px;
                img {
                  width: 22px;
                  margin-bottom: 8px;
                  margin-right: 12px;
                }
                &:after {
                  content: '';
                }
              }
            }
          }
        }
      }
    }

    .login2-navigation {
      display: block;
      padding: 10px 0 10px 30px;
      ul {
        li {
          display: block;
          button {
            font-weight: bold;
            text-transform: uppercase;
            color: #f26522;
            padding: 15px 0;
            display: block;
            font-size: 12px;
            &:after {
              content: '❯';
              width: 7px;
              height: 7px;
              text-align: center;
              margin-left: 12px;
              margin-top: -5px;
            }
          }
          a {
            display: flex;
            align-items: center;
            color: #000000;
            padding: 12px 0 12px 0;
            font-weight: bold;
            text-transform: uppercase;
            font-size: 12px;
          }
          ul {
            display: none;
            &.dropActiveOne,
            &.dropActiveTwo {
              display: block !important;
            }
            li {
              a {
                text-transform: unset;
                font-weight: normal;
                padding: 12px 0 12px 21px;
                img {
                  width: 22px;
                  margin-bottom: 8px;
                  margin-right: 12px;
                }
                &:after {
                  content: '';
                }
              }
            }
          }
        }
      }
    }

    .login-navigation {
      display: block;
      padding: 10px 0 10px 30px;
      ul {
        li {
          a {
            color: $LaranjaPottencial;
            display: block;
            font-weight: bold;
            padding: 15px 0;
            font-size: 12px;
          }
        }
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;
    pointer-events: initial;

    .close {
      opacity: 1;
      transition-delay: 0.3s;
    }

    .wrapper {
      transform: translateX(0);
    }
  }
}
