.button {
  color: #ffffff;
  font-size: 15px;
  padding: 13px 24px;
  border-radius: 50px;
  transition: background-position time(default) ease(inout);

  /** Mobile. */
  @media (max-width: 767px) {
    font-size: 14px;
    font-weight: 800;
    padding: 13px 20px;
  }

  @media (max-width: 320px) {
    font-size: 11px;
    width: 162px;
  }

  &-orange {
    background: #f26522 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 12px #00000029;
    background-size: 300% 100%;
    background-position: left center;
    align-self: center;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    background: #808080 0% 0% no-repeat padding-box;
  }

  &:hover {
    background-position: right center;
  }
}
