@import 'reset';
@import 'vars';

html,
body {
  scroll-behavior: smooth;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif;
  max-width: 100%;
  overflow-x: hidden;

  .page {
    width: 100vw;
    max-width: 100%;
  }

  &::before {
    content: '';
    background-color: black;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 1;
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity 600ms;
  }

  &.shadow {
    width: 100%;
    overflow: hidden;

    &::before {
      opacity: 0.6;
      pointer-events: initial;
    }
  }
}

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.containerFooter {
  width: 1709px;
  width: 100%;
  padding: 5rem 1rem;
  display: flex;
  flex-direction: column;
}

.container-text {
  width: 100%;
  margin: 0 5%;
}

@import 'elements/button';

@import 'sections/header';
@import 'sections/new-header';
@import 'sections/footer';
@import 'sections/menu';
