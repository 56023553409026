/* The Modal (background) */
.assist-modal {
  img {
    width: 100%;
  }

  .MuiPaper-root {
    max-width: 1000px;
    overflow: hidden;

    @media (max-width: 1280px) {
      max-height: none;
    }
  }

  .title {
    font-size: 20px;
    font-weight: bold;
    font-family: Poppins, sans-serif;
  }

  .text {
    color: #070606ad;
    display: flex;
    font-size: 14px;
    font-family: Open Sans, sans-serif;
  }

  .email {
    color: #f26522;
    padding-left: 5px;
  }

  .side-image {
    @media (max-width: 1050px) {
      display: none;
    }
  }

  .side-text {
    .box-side-text {
      width: 100%;
      padding: 20px 40px;
    }

    @media (max-width: 1050px) {
      width: 100%;
      max-width: 100%;
      flex-basis: 100%;
    }

    @media (max-width: 1280px) {
      display: flex;
      justify-content: center;
    }
  }

  a {
    color: #fff;
  }
}

/* The Close Button */
.close {
  height: 40px;
  display: flex;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
