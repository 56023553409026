.privacy-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-box-item {
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  background-color: #ffffff !important;
  border-radius: 15px;
  padding: 30px;
  position: relative;
  max-width: 355px;

  @media (max-width: 770px) {
    width: 300px;
  }
}

.modal-box-thumb {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  img {
    height: 60px;
  }
}

.modal-box-name {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  color: #e26d38;
  line-height: 1.5;
  padding-top: 10px;
}

.modal-box-title {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 600;
  padding: 0 0 0.8rem;
  color: #070606;
  line-height: 1.5;
}

.modal-box-list {
  display: flex;
  flex-direction: column;
  font-size: 15px;
  line-height: 1.8rem;

  span {
    font-weight: 100 !important;
  }

  .white-space {
    white-space: pre-line !important;
  }
}

.modal-box-list-item {
  line-height: 20px;
  display: flex;
  color: #070606;
}

.modal-content {
  border: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

button.close {
  position: absolute;
  top: 0px;
  right: 30px;
  cursor: pointer;
  font-size: 30px;
  color: #e26d38;
}

span.close {
  color: #e26d38;
  font-size: 14px;
  font-style: italic;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
