#main-footer {
  background: #f2f2f2 0% 0% no-repeat padding-box;
  position: relative;
  overflow: hidden;
  display: flex;
  padding: 90px 46px;
  line-height: 100%;
  z-index: 100;

  @media (max-width: 710px) {
    padding: 90px 20px;
  }
  
  .containerFooter {
    padding: 0 !important;
  }

  .flex-wrapper {
    display: flex;
    flex-wrap: wrap;
    left: 31px;
    align-items: flex-start;
    justify-content: left;

    /** Mobile. */
    @media (max-width: 1023px) {
      flex-wrap: wrap;
    }

    .center-logo {
      img {
        box-shadow: 5px 5px 8px rgb(0 0 0 / 12%);
      }
    }

    .left {
      flex-shrink: 0;

      /** Mobile. */
      @media (max-width: 1023px) {
        width: 100%;
      }

      &-logo {
        position: relative;

        a {
          display: inline-block;
        }
      }

      &-social {
        margin-top: 75px;
        margin-left: -4px;

        ul {
          list-style-type: none;
          display: flex;
          align-items: center;

          li {
            &:not(:last-child) {
              margin-right: 0px;
            }

            a {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 42.5px;
              height: 42.5px;
              border-radius: 4px;
              transition: background-color time(default) ease(inout);

              &:hover {
                background-color: transparent !important;
              }

              img:hover {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }

    .center {
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      margin-right: 0 !important;
      margin-left: 0 !important;
      width: 100% !important;

      &-title {
        font: normal normal 700 14px/20px Open Sans;

        /** Mobile. */
        @media (max-width: 1372px) {
          padding-top: 0 !important;
          margin-left: 0 !important;
        }

        @media (max-width: 1100px) {
          margin-right: 0;
          margin-left: 0 !important;
        }
      }

      .icon {
        align-items: flex-start;
        flex-shrink: 0;
        display: flex;
      }

      .tel {
        padding-left: 10px;
      }

      .item {
        position: relative;
        display: flex;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 15px;
        }

        &-link {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          z-index: 5;
          text-indent: -9999px;
        }

        &-icon {
          width: 30px;
          flex-shrink: 0;
        }

        &-title {
          color: #3c3c3c;
          font-size: 16px;
          font-weight: 400;
          line-height: 1.5;
          transition: color time(default) ease(inout);
        }

        &:hover .item-title {
          color: color(grey, dark);
        }
      }
    }

    .right {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      flex-shrink: 1;

      /** Mobile. */
      @media (max-width: 1372px) {
        width: 100%;
        flex-wrap: wrap;
        padding: 0 !important;
      }

      .column {
        /** Mobile. */
        @media (max-width: 1023px) {
          width: 100%;
        }

        &-title {
          font: normal normal bold 14px/83px Open Sans;
          letter-spacing: 0px;
          color: #3c3c3c;
          opacity: 1;
          font-size: 14px;
          text-transform: uppercase;
        }

        &-list {
          ul li {
            list-style-type: none;
            &:not(:last-child) {
              margin-bottom: 12px;
            }

            a {
              color: #3c3c3c;
              font-size: 16px;
              font-weight: normal;
              transition: color time(default) ease(inout);
              line-height: 1.5;

              &:hover {
                color: color(grey, dark);
              }
            }
          }
        }
      }
    }
  }

  .copyright {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;

    p {
      top: 4690px;
      left: 31px;
      font: normal normal normal 14px/35px Open Sans;
      color: #3c3c3c;
      opacity: 1;
    }
  }
}
